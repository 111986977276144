<template>
  <print-table v-if="form.id" :form="form" :historyRecord="historyRecord"
               title="劳动合同审批">
    <template slot="form">
      <div class="info_item">
        <div>姓名</div>
        <div>{{form.staffName}}</div>
      </div>
      <div class="info_item">
        <div>聘用部门</div>
        <div>{{form.sysDeptName.deptName||''}}</div>
      </div>
      <div class="info_item">
        <div>聘用岗位</div>
        <div>{{form.sysPositionNameList&&form.sysPositionNameList.length?form.sysPositionNameList.map((e) => e.positionName).join(","):''}}</div>
      </div>
      <div class="info_item">
        <div>变动原因</div>
        <div>{{form.changeReason}}</div>
      </div>
      <div class="info_item">
        <div>签订类别</div>
        <div>{{form.contractType==1?'首签':'续签'}}</div>
      </div>
      <div class="info_item">
        <div>首入中粮时间</div>
        <div>{{form.firstContractTime||'---'}}</div>
      </div>
      <div class="info_item">
        <div>原合同主体</div>
        <div>{{form.contractsPartOriginal}}</div>
      </div>
      <div class="info_item">
        <div>本次合同主体</div>
        <div>{{form.contractsPartA}}</div>
      </div>
      <div class="info_item">
        <div>合同期限</div>
        <div>{{form.contractsTerm}}</div>
      </div>
      <div class="info_item">
        <div>试用期</div>
        <div>{{form.isTrialPeriod?'是':'否'}}</div>
      </div>
      <div class="info_item">
        <div>合同开始日期</div>
        <div>{{form.contractsBeginTime}}</div>
      </div>
      <div class="info_item">
        <div>合同截止日期</div>
        <div>{{form.contractEndTime}}</div>
      </div>
      <div class="info_item">
        <div>试用开始日期</div>
        <div>{{form.beginTrailTime}}</div>
      </div>
      <div class="info_item">
        <div>试用截止日期</div>
        <div>{{form.endTrailTime}}</div>
      </div>
      <div class="info_item">
        <div>审批类型</div>
        <div>{{form.activityKeyName||''}}</div>
      </div>
    </template>
  </print-table>
</template>

<script>
import PrintTable from "@/components/print-table";
export default {
  name: "labor-contract-print",
  components: {PrintTable},
  data(){
    return {
      id:0,
      form:{},
      historyRecord:[],
    }
  },
  created(){
    this.id=this.$route.query.id;
    this.getDetail();
  },
  methods:{
    getDetail() {
      this.$api.personnel.getCtraDataDetail({id:this.id}).then((res) => {
        this.form = res.data;
        if (res.data.hasHistory) {
          this.getHistoryActivity(res.data.id);
        }
      })
    },
    getHistoryActivity(id){
      this.$api.personnel.getCtraHisFlow({id}).then(response=>{
        if(response.code===200){
          this.historyRecord = response.data.list;
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">


</style>